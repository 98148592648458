<script setup>
const props = defineProps({
    errorMessage: String,
    shake: Boolean, 
});
</script>

<template>
    <div class="error-message-container" :class="{ shake: props.shake }" v-if="props.errorMessage">
        <p class="error-message">{{ props.errorMessage }}</p>
    </div>
</template>

<style scoped>
.error-message-container {
    margin-top: 15px; 
    margin-bottom: 15px;
}

.error-message {
    color: red;
    font-size: 14px;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-3px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(3px);
  }
}

.shake {
  animation: shake 0.5s ease;
}
</style>