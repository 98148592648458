<script setup>
import { ref } from 'vue';
import AuthBtn from './AuthBtn.vue';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from './UserPool';
import ErrorMessage from './ErrorMessage.vue';

const props = defineProps({
    isOpen: Boolean,
});

const emit = defineEmits(['update:isOpen'])

const email = ref('');
const isCodeSent = ref(false)
const isPasswordReset = ref(false)
const verificationCode = ref('')
const newPassword = ref('')
const errorMessage = ref('')
const disabledBtn = ref(false)

function passwordChanged() {
    emit('update:isOpen', false);
}

const sendResetCode = () => {
    disabledBtn.value = true; 

    const cognitoUser = new CognitoUser({
        Username: email.value,
        Pool: UserPool,
    });

    cognitoUser.forgotPassword({
        onSuccess: (data) => {
            console.log('Code sent', data);
            disabledBtn.value = false; 
            isCodeSent.value = true;
        },
        onFailure: (err) => {
            console.error('Error:', err);
            disabledBtn.value = false; 
            errorMessage.value = err.message;
        },
    });
};

function submitResetPassword() {
    sendResetCode()
}

function submitNewPassword() {
    disabledBtn.value = true; 

    const cognitoUser = new CognitoUser({
        Username: email.value,
        Pool: UserPool,
    });

    cognitoUser.confirmPassword(verificationCode.value, newPassword.value, {
        onSuccess: () => {
            console.log('Password successfully changed!');
            isCodeSent.value = false;
            isPasswordReset.value = true;
            disabledBtn.value = false; 
        },
        onFailure: (err) => {
            console.error('Error:', err);
            errorMessage.value = err.message;
            disabledBtn.value = false; 
        },
    });
}
</script>

<template>
    <div class="forget-password">
        <div class="forget-password-container">
            <ErrorMessage :errorMessage="errorMessage" :shake="true" />
            <div class="reset-password-container" v-if="!isCodeSent && !isPasswordReset">
                <form @submit.prevent="submitResetPassword()">
                    <h2>Reset Password</h2>

                    <p>Enter your e-mail and we will send you the instructions to reset password</p>

                    <input v-model="email" class="auth-input" name="email" type="email" placeholder="E-mail" required />

                    <AuthBtn text="Reset Password" :isDisabled="disabledBtn"></AuthBtn>
                </form>
            </div>
            <div class="reset-code-container" v-if="isCodeSent">
                <form @submit.prevent="submitNewPassword()">

                    <h2>Step 1: Enter Verification Code</h2>
                    <p>We've sent a verification code to your email. Please enter it below to proceed.</p>

                    <input type="text" class="auth-input" placeholder="Your code" v-model="verificationCode">

                    <h2>Step 2: Set a New Password</h2>
                    <p>Set a new password for your account.</p>

                    <input v-model="newPassword" class="auth-input" type="password" placeholder="New password" required />

                    <AuthBtn text="Submit" :isDisabled="disabledBtn"></AuthBtn>
                </form>
            </div>
            <div class="password-changed-container" v-if="isPasswordReset">
                <div class="checkmark"></div>
                <h2>
                    Your email has been successfully verified.<br>
                    Click below to log in and access your account.
                </h2>
                
                <AuthBtn text="Go to Login" @click="passwordChanged" :isDisabled="disabledBtn"></AuthBtn>
            </div>
        </div>
    </div>
</template>

<style scoped>
.forget-password-container p {
    margin-bottom: 20px;
    margin-top: 20px;
}

.forget-password .checkmark {
    margin-bottom: 50px;
}

.reset-password-container input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--vt-c-white);
    margin-bottom: 20px;
}

.auth-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--vt-c-white);
    margin-bottom: 20px;
}

.checkmark {
    display: inline-block;
    width: 100px;
    height: 50px;
    border: 15px solid #004D1C;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
}

</style>
