<template>
  <div v-if="isOpen" class="sign-in-overlay">
    <div class="form-container">

      <div class="close-cross" @click="closeModal">
        <svg width="20" height="20" viewBox="0 0 24 24">
          <line x1="4" y1="4" x2="20" y2="20" stroke="white" stroke-width="4" stroke-linecap="round" />
          <line x1="20" y1="4" x2="4" y2="20" stroke="white" stroke-width="4" stroke-linecap="round" />
        </svg>
      </div>

      <div v-if="!emailVerificationShow && !ressetPasswordShow" :class="{ shake: errorMessage }">
        <ErrorMessage :errorMessage="errorMessage" :shake="true" />
        <h1>{{ determSignType() ? 'Sign in' : 'Sign up' }}</h1>

        <form @submit.prevent="submitAuthForm(determSignType())">
          <div class="auth-container">

            <div class="auth-email-container">
              <input v-model="email" class="auth-input" name="email" type="email" placeholder="E-mail" required />
            </div>

            <div class="auth-password-container">
              <input v-model="password" class="auth-input" type="password" placeholder="Password" required />
            </div>

          </div>

          <div v-if="determSignType()" class="forgot-password-container" @click="initiateForgotPassword()">
            Forgot password?
          </div>

          <div class="auth-conditions" v-if="!determSignType()">
            <p>By continuing, you're setting up an AI Nikki account and agree to our
              <a href="/user-agreement.html" class="link">User Agreement</a> and
              <a href="/privacy.html" class="link">Privacy Policy</a>.
            </p>
            <div class="checkbox-container">
              <div>
                <input class="checkbox" type="checkbox" id="email-agreement" v-model="agreesToEmails" />
                <label for="email-agreement">Receive promotions and discounts</label>
              </div>
              <div>
                <input class="checkbox" type="checkbox" id="age-concern" v-model="ageConcern" />
                <label for="age-concern">I confirm that I am 18 years of age or older</label>
              </div>
            </div>
          </div>

          <button :disabled="isSubmitting" type="submit" class="submit-button">
            <span v-if="isSubmitting">Processing...</span>
            <span v-else>{{ determSignType() ? 'Sign in' : 'Sign up' }}</span>
          </button>
        </form>

        <div class="federated-signin">
          <div class="button-container">
            <button class="federated-button google-button" @click="signInWithGoogle">
              <img src="/icons/google.png" alt="Google Icon" class="federation-icon" />
              CONTINUE WITH GOOGLE
            </button>
          </div>
        </div>

        <div class="sign-footer-container">
          <div class="sign-footer">
            <div class="text">{{ determSignType() ? "Don't have an account yet?" : "Already have an account?" }}</div>
            <div class="link" @click="changeSignType">{{ determSignType() ? "Sign up" : "Sign in" }}</div>
          </div>
        </div>
      </div>
      <EmailVerification v-else-if="emailVerificationShow" :email="email" v-model:isOpen="emailVerificationShow" :close="closeModal" />
      <ForgotPassword v-if="ressetPasswordShow" v-model:isOpen="ressetPasswordShow" :close="closeModal" />
    </div>
  </div>
</template>

<script setup>

import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { ref, watch } from 'vue';
import { useChatStore } from '@/stores/chat.js';
import UserPool from './UserPool';
import EmailVerification from './EmailVerification.vue';
import ForgotPassword from './ForgotPassword.vue';
import ErrorMessage from './ErrorMessage.vue';


const props = defineProps({
  isOpen: Boolean,
  signType: Boolean
});

const emit = defineEmits(['update:isOpen'])

const closeModal = () => {
  emit('update:isOpen', false);
  ressetPasswordShow.value = false; 
  emailVerificationShow.value = false; 
  email.value = ''; 
  password.value = '';
  errorMessage.value = '';
  isSubmitting.value = false; 
}

const chatStore = useChatStore();
function generateRandomState() {
  return 'state_' + Math.random().toString(36).substr(2, 9);
}

const agreesToEmails = ref(true);
const ageConcern = ref(true);
const errorMessage = ref('');
const email = ref('');
const password = ref('');
const emailVerificationShow = ref(false);
const ressetPasswordShow = ref(false); 
const isSubmitting = ref(false)

watch(agreesToEmails, (newVal) => {
  if (newVal) {
    errorMessage.value = '';
  }
});

function changeSignType() {
  emit('update:signType', props.signType === 'logIn' ? 'signUp' : 'logIn');
}

function initiateForgotPassword() {
  ressetPasswordShow.value = true; 
}

function determSignType() {
  return props.signType == "logIn" ? true : false;
}

function base64UrlDecode(str) {
  try {
    let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
    return atob(base64);
  } catch (error) {
    alert("Base64 decoding failed:", error);
    return null;
  }
}

async function handleSessionAndNavigate() {
  const selectedCharacter = sessionStorage.getItem('selectedCharacter');
  const chid = selectedCharacter ? JSON.parse(selectedCharacter)?.chid : chatStore.characters[0].chid;

  await chatStore.getSessionId(sessionStorage.getItem('userId'), chid, false);

  window.location.href = `/chat.html?${chatStore.sessionId}`;
}

function submitAuthForm(type) {
  if (confirmationCheck()) {
    isSubmitting.value = true; 
    if (type) {
      // Sign In

      const userData = {
        Username: email.value,
        Pool: UserPool
      };

      const cognitoUser = new CognitoUser(userData);

      const authenticationData = {
        Username: email.value,
        Password: password.value,
      };

      const authenticationDetails = new AuthenticationDetails(authenticationData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const accessToken = result.getAccessToken().getJwtToken();
          const idToken = result.getIdToken().getJwtToken();

          if (accessToken) {
            const tokenParts = idToken.split('.');
            if (tokenParts.length !== 3) {
              alert('Invalid ID Token format')
              throw new Error('Invalid ID Token format');
            }

            const decodedPayload = base64UrlDecode(tokenParts[1]);
            if (!decodedPayload) {
              alert('Failed to decode ID Token payload')
              throw new Error('Failed to decode ID Token payload');
            }
            const userInfo = JSON.parse(decodedPayload);
            console.log("Decoded User Info:", userInfo);


            const userId = userInfo.sub;
            if (!userId) {
              alert('User ID (sub) missing in decoded token')
              throw new Error('User ID (sub) missing in decoded token');
            }

            sessionStorage.setItem("userId", userId)
            chatStore.setUserId(userId)

            sessionStorage.setItem('accessToken', accessToken);
            sessionStorage.setItem('idToken', idToken);
            sessionStorage.setItem('username', `Sinner`);
            sessionStorage.setItem('clientId', chatStore.cognitoClientId)
            emit('authSuccess');
            chatStore.loadSelectedCharacter()

            sessionStorage.setItem('isAuthorized', 'true');
            isSubmitting.value = false; 
            handleSessionAndNavigate();
          }

        },
        onFailure: (err) => {
          errorMessage.value = err.message;
          isSubmitting.value = false;
          console.error('Authentication failed:', err);
        }
      });

    } else {
      // Sign Up
      UserPool.signUp(email.value, password.value, [], null, (err, data) => {
        if (err) {
          errorMessage.value = err.message;
          console.log(err);
          isSubmitting.value = false; 
          
        } else {
          emailVerificationShow.value = true;
          isSubmitting.value = false; 
        }
      })

    }
  }
}

const confirmationCheck = () => {
  if (!agreesToEmails.value) {
    errorMessage.value = 'You must agree to get emails from Nikki before continuing.';
    triggerShake();
    return false;

  } else if (!ageConcern.value) {
    errorMessage.value = 'Please confirm that you 18 years or older.';
    triggerShake();
    return false;
  }

  return true;
}

function signInWithGoogle() {
  if (confirmationCheck()) {
    const state = generateRandomState();
    sessionStorage.setItem('oauth_state', state);
    sessionStorage.setItem('agreesToEmails', agreesToEmails.value);
    const redirectUri = encodeURIComponent(
      `${window.location.origin || chatStore.fallBackURL}/callback.html`);
    console.log(redirectUri)
    const scope = 'email openid profile';

    const authUrl = `${chatStore.cognitoDomain}/oauth2/authorize?` +
      `response_type=token&` +
      `client_id=${chatStore.cognitoClientId}&` +
      `redirect_uri=${redirectUri}&` +
      `state=${state}&` +
      `scope=${scope}&` +
      `identity_provider=Google`;
    sessionStorage.setItem('clientId', chatStore.cognitoClientId)
    window.location.href = authUrl;

    console.log('Signing in with Google');
  }
}

function triggerShake() {
  const formContainer = document.querySelector('.form-container');
  formContainer.classList.add('shake');
  setTimeout(() => {
    formContainer.classList.remove('shake');
  }, 350);
}

</script>

<style scoped src="./signIn.css" />
