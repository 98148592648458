<template>
  <header class="header">
    <img src="/logo.webp" alt="AI Nikki Logo" class="logo" @click="goToHome"/>
    <button class="toggle-button" v-if="!isMenuVisible" @click="toggleMenu">
      <span class="menu-icon">&#9776;</span> <!-- Hamburger icon -->
    </button>
    <div class="toggle-menu-container" v-if="isMenuVisible">
      <button class="close-menu" @click="toggleMenu">✖</button>
      <div class="toggle-menu-content">
        <!-- <button class="action-button" @click="goToAboutUs">About Us</button> -->
        <button class="action-button" @click="handleSignIn">
          {{ isAuthorized ? 'Menu' : 'Sign In' }}
        </button>
        <div class="social-media-buttons">
          <button class="sm-button" @click="goToFacebook">
            <i class="fab fa-facebook-f"></i>
          </button>
          <button class="sm-button" @click="goToInstagram">
            <i class="fab fa-instagram"></i>
          </button>
          <button class="sm-button" @click="goToTicToc">
            <i class="fab fa-tiktok"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="button-container" v-if="!isMenuVisible">
      <div class="social-media-buttons">
        <button class="sm-button" @click="goToFacebook">
          <i class="fab fa-facebook-f"></i>
        </button>
        <button class="sm-button" @click="goToInstagram">
          <i class="fab fa-instagram"></i>
        </button>
        <button class="sm-button" @click="goToTicToc">
          <i class="fab fa-tiktok"></i>
        </button>
      </div>
      <button class="action-button" @click="goToAboutUs">About Us</button>
      <button class="action-button" @click="handleSignIn">
        {{ isAuthorized ? 'Menu' : 'Sign In' }}
      </button>
    </div>
    <div :class="['toggle-user-menu-container', { open: isUserMenuVisible }]">
      <button class="close-menu" @click="toggleUserMenu">✖</button>
      <div class="toggle-user-menu-content">
        <h3>Welcome, {{ username }}</h3>
          <button class="menu-button" @click="goToProfile">
            <i class="fas fa-solid fa-address-card"></i>  Profile
          </button>
          <button class="menu-button" v-if="isAuthorized" @click="goToChat">
            <i class="fa-solid fa-comment-dots"></i> Back to Chat
          </button>
          <button class="menu-button" @click="logout">
            <i class="fa-solid fa-circle-left"></i>  Logout
          </button>
      </div>
    </div>
    <SignInPopUp v-if="showOverlay" v-model:isOpen="showOverlay" v-model:signType="signType" @authSuccess="onAuthSuccess"/>
  </header>
</template>

<script setup>

import {ref, onMounted, onUnmounted, watch} from 'vue';
import {useChatStore} from '@/stores/chat.js';
import SignInPopUp from './SignInPopUp.vue';

const chatStore = useChatStore();
const isMenuVisible = ref(false);
const isUserMenuVisible = ref(false);
const isAuthorized = ref(false);
const username = ref('Guest');
const showOverlay = ref(false);
const signType = ref("logIn")

function onAuthSuccess() {
  isAuthorized.value = true;
  sessionStorage.setItem('isAuthorized', 'true');
  showOverlay.value = false;
  chatStore.setSelectedCharacter(character);
  goToChat();
}

function goToChat() {
  window.location.href = `/chat.html`;
}

function handleOutsideClick(event) {
  const userMenu = document.querySelector('.toggle-user-menu-container');
  if (isUserMenuVisible.value && userMenu && !userMenu.contains(event.target)) {
    toggleUserMenu(); // Close the menu
  }
}

onMounted(() => {
  document.addEventListener('mousedown', handleOutsideClick); // Attach the event listener
});

onUnmounted(() => {
  document.removeEventListener('mousedown', handleOutsideClick); // Clean up the event listener
});

onMounted(() => {
  const authStatus = sessionStorage.getItem('isAuthorized');
  const storedUsername = sessionStorage.getItem('username') || 'Guest';
  isAuthorized.value = authStatus === 'true';
  username.value = storedUsername;
});

watch(isAuthorized, (newVal) => {
  console.log('isAuthorized changed to:', newVal);
});

function goToFacebook() {
  window.open('https://www.facebook.com/', '_blank');
}

function goToInstagram() {
  window.open('https://www.instagram.com/', '_blank');
}

function goToTicToc() {
  window.open('https://www.tiktok.com/', '_blank');
}

function goToAuth() {
  console.log('Navigating to Auth Page...');
  window.location.href = '/auth.html';
}

function goToAboutUs() {
  window.location.href = '/about.html';
}

function goToHome() {
  window.location.href = '/';
}

function goToProfile() {
  // Logic to navigate to the user's profile page
  console.log('Navigating to profile page...');
}

function toggleMenu() {
  isMenuVisible.value = !isMenuVisible.value;
  if (isMenuVisible.value) {
    isUserMenuVisible.value = false;
  }
}
function toggleUserMenu() {
  if (isUserMenuVisible.value) {
    const userMenu = document.querySelector('.toggle-user-menu-container');
    userMenu.classList.add('closing');

    setTimeout(() => {
      isUserMenuVisible.value = false;
      userMenu.classList.remove('closing');
    }, 500);
  } else {
    isUserMenuVisible.value = true;
    const userMenu = document.querySelector('.toggle-user-menu-container');
    userMenu.classList.add('open');
    if (isUserMenuVisible.value) {
    isMenuVisible.value = false;
  }
  }
}

function logout() {
  const clientId = sessionStorage.getItem('clientId');
  const logoutUri = encodeURIComponent(`${window.location.origin}/`);


  if (clientId) {
    const logoutUrl = `${chatStore.cognitoDomain}/logout?client_id=${clientId}&logout_uri=${logoutUri}`;

    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('isAuthorized');
    sessionStorage.removeItem('username');
    isAuthorized.value = false;
    chatStore.clearChatHistories();
    window.location.href = logoutUrl;
  } else {
    console.error('Client ID not found');
    alert('Failed to log out. Please try again.');
  }
}

const character =   {
    name: 'Nikki',
    image: '/images/56467421-af98-4062-8353-39223096d99d.webp',
    chid: "56467421-af98-4062-8353-39223096d99d",
    description: 'Hi, I’m all about passion and deep connections. Ready to dive into something unforgettable?',
}

function handleSignIn() {
  if (isAuthorized.value) {
    toggleUserMenu();
  } else {
    if (!isAuthorized.value) {
      sessionStorage.setItem('selectedCharacter', JSON.stringify(character));
      showOverlay.value = true;
    }
  }
}

</script>

<style scoped src="./header.css"/>
