<script setup>
    import { ref, inject } from 'vue';
    import UserPool from './UserPool';
    import { CognitoUser } from 'amazon-cognito-identity-js';
    import AuthBtn from './AuthBtn.vue';

    const props = defineProps({
        isOpen: Boolean,
        email: String,
    });

    const verificationCode = ref('')
    const emailVerified = ref(false)
    const emailVerificationError = ref(false)
    const signType = inject('signType');

    const emit = defineEmits(['update:isOpen'])

function submitEmailVerificationCode() {

    return new Promise((resolve, reject) => {
        const userData = {
            Username: props.email,
            Pool: UserPool
        };
        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(verificationCode.value, true, (err, result) => {
            if (err) {
                console.log(err)
                reject(err)
                // emailVerificationError.value = true; 
            } else {
                if (result) {
                    console.log('Email successfully verified');
                    emailVerified.value = true; 
                    resolve(result);
                }
            }
        });
    });

}

function emailVerificationFinished() {
    emit('update:isOpen', false);
    if (signType) {
        signType.value = 'logIn';
    }
}

</script>

<template>
    <div class="email-verification">
        <div class="email-verification-container" v-if="!emailVerified && !emailVerificationError">
            <div class="email-verification-title">
                <h1>Confirm your E-mail</h1>
            </div>

            <div class="email-verification-body">
                <p>We have sent a confirmation code to {{ props.email }}</p>
                <p>If you don't see it, check your SPAM folder.</p>
            </div>

            <form @submit.prevent="submitEmailVerificationCode()">
                <div class="email-verification-form">
                    <input type="text" placeholder="Your code" v-model="verificationCode">
                    <AuthBtn text="Send"></AuthBtn>
                </div>
            </form>
        </div>

        <div v-if="emailVerified" class="email-verified">
            <div class="checkmark"></div>
            <div class="email-verified-title">
                <h2>
                    Your email has been successfully verified.<br>
                    Click below to log in and access your account.
                </h2>
            </div>

            <AuthBtn text="Go to Login" @click="emailVerificationFinished"></AuthBtn>
        </div>

        <div v-if="emailVerificationError" class="email-error">
            Something went wrong
        </div>
    </div>
</template>

<style scoped>
    .email-verification-title {
        margin-bottom: 20px;
    }

    .email-verification-body p {
        margin-bottom: 10px;
    }

    .email-verification-form {
        margin-top: 20px;
    }

    .email-verified-title {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .email-verification-form input {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            background-color: rgba(255, 255, 255, 0.1);
            color: var(--vt-c-white);
            margin-bottom: 20px;
    }

    .submit-button {
        padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  color: var(--vt-c-white);
  border: 2px solid var(--vt-c-white);
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease, border-color 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  width: 100%;
    }

    .checkmark {
  display: inline-block;
  width: 100px; 
  height: 50px;
  border: 15px solid #004D1C;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}

</style>
